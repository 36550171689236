<template>
  <el-dialog
    title="排序"
    :visible.sync="show"
    append-to-body
    width="30%"
    top="20px"
    :before-close="() => $emit('close')"
  >
    <draggable
      @start="drag = true"
      @end="drag = false"
      draggable=".template-item"
      v-model="list"
      scroll="true"
    >
      <div class="template-item" v-for="(item, index) in list" :key="index">
        <div v-if="Array.isArray(labelProps)">
          {{ item[labelProps[0]] + "：" + item[labelProps[1]] }}
        </div>
        <div v-else>{{ item[labelProps] }}</div>
        <i class="iconfont icon-menu"></i>
      </div>
    </draggable>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('close')">取 消</el-button>
      <el-button type="primary" @click="handleSortSubmit">保存排序</el-button>
    </span>
  </el-dialog>
</template>

<script>
import draggable from "vuedraggable";
export default {
  name: "Sort",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    sortList: {
      type: Array,
      default: () => [],
    },
    labelProps: {
      type: [Array, String],
      default: "name",
    },
  },
  data() {
    return {
      list: [],
    };
  },
  watch: {
    sortList: {
      handler(newV) {
        this.list = [...this.sortList];
      },
      immediate: true,
    },
  },
  methods: {
    handleSortSubmit() {
      this.$emit("close");
      const sort = this.list.map((item) => item.id);
      this.$emit("sortChange", sort);
    },
  },
  components: {
    draggable,
  },
};
</script>

<style lang='scss' scoped>
.template-item {
  display: flex;
  margin-bottom: 10px;
  background-color: #f6f6f6;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-radius: 4px;
  cursor: move;
}
/deep/ .el-dialog__body {
  padding: 8px 20px !important;
}
/deep/ .el-dialog__footer {
  padding-top: 0 !important;
  padding-bottom: 10px !important;
}
</style>
