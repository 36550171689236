import request from './config'

/**
 * 获取权限菜单
 */
export function getAuth () {
  return request.get('/permission')
}

/**
 * 获取权限详情
 * id  权限id
 */
export function getAuthDetail (id) {
  return request.get(`/permission/${id}`)
}

/**
 * 编辑权限
 * params  {parentId? ,permissionCode, permissionName, id?}
 */
export function editAuth ({ id, ...params }) {
  if (id) {
    return request.put(`/permission/${id}`, params)
  } else {
    return request.post('/permission', params)
  }
}

/**
 * 删除权限
 * id  权限id
 */
export function deleteAuth (id) {
  return request.delete(`/permission/${id}`)
}

/**
 * 权限菜单排序
 * ids  排序权限id按序逗号拼接
 */
export function sortAuth (ids) {
  return request.put(`/permission/sort/${ids}`)
}

/**
 * 移动权限菜单
 * permissionIds  要移动的权限id拼接
 * permissionId  移动到的菜单id
 */
export function moveAuth (permissionIds, permissionId) {
  return request.put(`/permission/moveMenu/${permissionIds}`, { permissionId })
}

/**
 * 获取用户拥有权限
 * adminUserId  用户id
 */
export function getUserAuth (adminUserId) {
  return request.get(`/permission/user/${adminUserId}`)
}

/**
 * 设置用户拥有权限
 * adminUserId  用户id
 * permissionIds 设置权限id拼接
 */
export function setUserAuth (adminUserId, permissionIds) {
  return request.put(`/permission/user/${adminUserId}`, { permissionIds })
}
