<template>
  <div
    v-loading="loading"
    element-loading-text="数据加载中"
    class="main-content border-radius-6 padding-20 box-shadow-light-grey"
  >
    <div class="operation">
      <el-row class="head-row padding-bottom-20">
        <el-col :span="18">
          <el-button @click="_getMenuList">刷新数据</el-button>
          <el-button
            class="custom-button-margin-left"
            type="primary"
            @click="isMove = true"
            >权限移动</el-button
          >
        </el-col>
        <el-col :span="6" class="te_right">
          <el-button
            type="danger"
            @click="deleteSelectNode"
            icon="el-icon-delete"
            :disabled="!canDeleteBatch"
            >删除菜单
          </el-button>
        </el-col>
      </el-row>
    </div>
    <el-tree
      class="p_20 border-radius-6"
      :data="menuList"
      show-checkbox
      node-key="id"
      :default-expanded-keys="expandNodes"
      ref="tree"
      @node-expand="handleNodeExpand"
      @check="selectNode"
      :expand-on-click-node="false"
    >
      <div class="custom-tree-node" slot-scope="{ node, data }">
        <span>
          <span>{{ node.label }}</span>
        </span>
        <span>
          <el-button
            v-if="data.children"
            type="text"
            size="mini"
            @click.native="handleNodeSort(data)"
          >
            子节点排序
          </el-button>
          <el-button
            type="text"
            size="mini"
            @click="() => operateMenu(1, node, data)"
          >
            添加子菜单
          </el-button>
          <el-button
            v-if="data.id !== 0"
            type="text"
            size="mini"
            @click="() => operateMenu(2, node, data)"
          >
            编辑菜单
          </el-button>
          <el-button
            v-if="data.id !== 0"
            type="text"
            size="mini"
            class="del-text"
            @click="() => operateMenu(3, node, data)"
          >
            删除菜单
          </el-button>
        </span>
      </div>
    </el-tree>
    <el-drawer
      :title="form.id ? '修改菜单' : '新增子菜单'"
      :visible.sync="dialogFormVisible"
      size="500px"
    >
      <el-form
        :model="form"
        label-width="100px"
        ref="form"
        :rules="rules"
        v-loading="formLoading"
      >
        <el-form-item label="权限标识" prop="permissionCode">
          <el-input
            placeholder="请输入权限标识"
            v-model="form.permissionCode"
            style="width: 90%"
          >
          </el-input>
          <p class="tip-text">没有权限时以'/'代替</p>
        </el-form-item>
        <el-form-item label="权限名称" prop="permissionName">
          <el-input
            placeholder="请输入权限名称"
            v-model="form.permissionName"
            style="width: 90%"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleMenuOperate">保 存</el-button>
      </div>
    </el-drawer>
    <el-drawer
      title="权限移动"
      direction="rtl"
      size="550px"
      :visible.sync="isMove"
    >
      <el-form label-width="100px">
        <el-form-item label="移动权限">
          <el-cascader
            :show-all-levels="false"
            ref="moveTree"
            style="width: 90%"
            :options="moveList"
            :props="{
              value: 'id',
              emitPath: false,
              multiple: true,
            }"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="移动到">
          <el-cascader
            style="width: 90%"
            :options="menuList"
            :props="{
              value: 'id',
              checkStrictly: true,
            }"
            clearable
            v-model="permissionId"
          ></el-cascader>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button @click="closeMoveModel">取 消</el-button>
        <el-button type="primary" @click="handleMoveSubmit">确定</el-button>
      </div>
    </el-drawer>
    <Sort
      :show="isSort"
      :sortList="sortList"
      @close="isSort = false"
      labelProps="label"
      @sortChange="handleSortNode"
    />
  </div>
</template>

<script>
import {
  getAuth,
  editAuth,
  deleteAuth,
  getAuthDetail,
  sortAuth,
  moveAuth,
} from "@/api/auth";
import Sort from "@/components/Sort";

const none = {
  permissionCode: "",
  permissionName: "",
  expandNodes: [],
};

export default {
  name: "Menu",
  data() {
    return {
      loading: false,
      formLoading: false,
      dialogFormVisible: false,
      menuList: [],
      form: {},
      canDeleteBatch: false,
      activeNode: null,
      nodeData: null,
      isSort: false,
      sortList: [],
      isMove: false,
      permissionId: null, // 移动到的权限
      treeExpandData:[],
      rules: {
        permissionCode: [
          { required: true, trigger: "blur", message: "请输入权限名称" },
        ],
        permissionName: [
          { required: true, trigger: "blur", message: "请输入权限名称" },
        ],
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => vm._getMenuList());
  },
  computed: {
    moveList() {
      return this.menuList[0] ? this.menuList[0].children : [];
    },
  },
  methods: {
    // 缓存上一次展开的节点，避免调用接口后页面刷新需要手动重新打开
    handleNodeExpand(data) {
      this.$store.commit("setAuthNodeExpanded", [1, data.id]);
    },
    operateMenu(type, node, data) {
      this.activeNode = node;
      this.nodeData = data;
      switch (type) {
        case 1:
          this.form = { ...none, parentId: data.id };
          this.dialogFormVisible = true;
          break;
        case 2:
          this.formLoading = true;
          getAuthDetail(data.id)
            .then((res) => {
              const { permissionCode, permissionName, id } = res;
              this.form = { permissionCode, permissionName, id };
            })
            .finally(() => {
              this.formLoading = false;
            });
          this.dialogFormVisible = true;
          break;
        case 3:
          this._deleteMenu();
          break;
      }
    },
    handleMenuOperate() {
      editAuth(this.form).then(() => {
        this._getMenuList();
        this.$message({
          type: "success",
          message: "保存成功!",
        });
        this.dialogFormVisible = false;
      });
    },
    closeMoveModel() {
      this.isMove = false;
      this.permissionId = null;
    },
    handleMoveSubmit() {
      const { permissionId } = this;
      const moveId = this.$refs.moveTree
        .getCheckedNodes(false)
        .map((item) => item.value);
      if (moveId.includes(permissionId)) {
        this.$message.error("选择有误，不可将权限移动至当前权限下");
        return;
      }
      moveAuth(moveId.join(), permissionId[permissionId.length - 1]).then(
        () => {
          this.$message.success("保存成功");
          this.isMove = false;
          this._getMenuList();
          this.permissionId = null;
        }
      );
    },
    handleNodeSort(data) {
      this.sortList = data.children;
      this.isSort = true;
    },
    handleSortNode(sort) {
      sortAuth(sort).then(() => {
        this.$message.success("排序成功");
        this._getMenuList();
      });
    },
    selectNode(node, selected) {
      this.canDeleteBatch = selected.checkedKeys.length !== 0;
    },
    deleteSelectNode() {
      const selected = this.$refs.tree.getCheckedKeys();
      this._deleteMenu(selected);
    },

    _deleteMenu(selectId) {
      this.$confirm("此操作将删除该菜单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let id = "";
          if (selectId) {
            id = selectId.join(",");
          } else {
            id = this.nodeData.id;
          }
          deleteAuth(id).then(() => {
            this._getMenuList();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    _getMenuList() {
      this.loading = true;
      getAuth()
        .then((res) => {
          this.menuList = res
        })
        .finally(() => {
          this.loading = false;
        });
    },
    _resetFormData() {
      this.$refs.form.resetFields();
    },
  },
  components: {
    Sort,
  },
  created() {
    this.expandNodes = this.$store.state.authNodeExpanded;
  },
  updated() {
    this.expandNodes = this.$store.state.authNodeExpanded;
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.main-content {
  //   padding: 10px;
  background: #fff;
  // height: calc(100vh - 176px);
}
.custom-tree-node {
  width: 800px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.te_right {
  text-align: right;
}

.m_bottom_20 {
  margin-bottom: 20px;
}

.p_20 {
  height: calc(100vh - 262px);
  overflow-y: auto;
}

.del-text {
  color: #f56c6c;
}

.tip-text {
  padding-left: 10px;
  font-size: 12px;
  color: #777;
}
.operation {
  background: #fff;
  // height:52px;
  // line-height: 52px;
  // margin-bottom:16px;
  // padding-left:20px;
}
.part {
  margin-top: 20px;
  box-sizing: border-box;
  padding: 15px;
  background: #fff;
  border-radius: 2px;
  box-shadow: -2px -2px 10px rgba(180, 180, 180, 0.1);
}
// ! 还原样式
// /deep/ .el-tree-node{
// /deep/ .el-tree-node__content {
//    /deep/.custom-tree-node {
//     font-size: 14px;
//     color: #333;
//     flex: 1;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     width: 100%;
//   }
// }
// }

/deep/ .el-drawer {
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
.dialog-footer {
  margin-top: 40px;
  text-align: center;
}
</style>
